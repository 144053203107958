/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-unused-vars */
/* eslint-disable comma-dangle */
/* eslint-disable jsx-quotes */
/* eslint-disable react/jsx-filename-extension */
import './App.css';
import 'react-chat-widget/lib/styles.css';

import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { addResponseMessage } from 'react-chat-widget';
import { RedirectToLogin, SetAccessToken, SetCsrfToken } from './util/cookies';

import ChatBot from './components/ChatBot/chatBot';
import LoginPage from './components/LoginPage';
import LogoutButton from './components/LogoutButton';
import RedirectPage from './components/RedirectPage';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem('isLoggedIn') || false
  );
  const location = useLocation();
  const navigate = useNavigate();
  const isLogoutVisible = !['/login', '/userloggedin'].includes(
    location.pathname
  );
  const timerRef = useRef({ timerId: null });
  let interval;

  useEffect(() => {
    // Check if the user is already logged in using the access token in cookies
    addResponseMessage('Welcome to this **awesome** chat!');
  }, []);

  const refreshAccessToken = async () => {
    try {
      // Make a request to get a new access token using the refresh token from the backend
      const accessToken = SetAccessToken(navigate);
      const csrfToken = SetCsrfToken(navigate);
      if (accessToken && csrfToken) {
        const csrftoken = document.cookie
          .split(';')
          .filter((item) => item.trim().startsWith('csrftoken'))[0]
          .split('=')[1];

        const response = await fetch(
          `${process.env.REACT_APP_WO_BACKEND_URL}/chat_bot_rasa/be/refresh_token/`,
          {
            method: 'POST',
            credentials: 'include', // Include cookies in the request
            headers: {
              'content-type': 'application/json',
              accept: 'application/json',
              'X-CSRFToken': `${csrftoken}`,
            },
          },
          { withCredentials: true }
        );
        if (response.ok) {
          setIsLoggedIn(true); // Update the login state after getting the new access token
        }
      } else RedirectToLogin(navigate);
    } catch (error) {
      console.error('Failed to get new access token:', error);
    }
  };

  const startRefresher = () => {
    interval = setInterval(() => {
      refreshAccessToken();
    }, 40000);
    timerRef.current.timerId = interval;
  };

  const stopRefresher = () => {
    if (timerRef.current.timerId || timerRef.current.timerId === 0) {
      clearInterval(timerRef.current.timerId);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      startRefresher();
    } else {
      stopRefresher();
    }
    return () => stopRefresher();
  }, [isLoggedIn]);

  const handleLogin = () => {
    localStorage.setItem('isLoggedIn', true);
    setIsLoggedIn(true);
  };

  return (
    <div className='App'>
      {isLogoutVisible && <LogoutButton setIsLoggedIn={setIsLoggedIn} />}
      <Routes>
        <Route exact path='/' element={<Navigate to='/login' replace />} />
        <Route path='/login' element={<LoginPage />} />
        <Route
          path='/userloggedin'
          element={<RedirectPage handleLogin={handleLogin} />}
        />
        <Route
          path='/chat'
          element={
            isLoggedIn ? (
              <ChatBot
                url={`${process.env.REACT_APP_WO_BACKEND_URL}/chat_bot_rasa/be/chat/`}
              />
            ) : (
              <Navigate to='/login' />
            )
          }
        />
      </Routes>
    </div>
  );
}

export default App;
