/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable comma-dangle */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable object-curly-spacing */
/* eslint-disable no-unused-vars */
/* eslint-disable operator-linebreak */
/* eslint-disable jsx-quotes */
/* eslint-disable import/order */
import './manualForm.css';

import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import CategoryAndSubcategoryJSON from '../../CategoryAndSubcategory.json';
import ConfirmationModal from '../Modals/confirmationModal';
import { LoadingButton } from '@mui/lab';
import { ModalClose } from '@mui/joy';
import PriorityConstants from '../../constants/priorityConstants';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

function ManualForm({
  isManualFormVisible,
  onClose,
  loading,
  placeWO,
  tenantDetails,
}) {
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);
  const maxBriefDescription = 35;
  const maxProblemDescription = 1500;
  const maxTechnicianNotes = 1500;
  const maxAccessNotesLength = 1500;

  const formik = useFormik({
    initialValues: {
      categorization: '',
      subCategory: '',
      prioritization: '',
      technicianNotes: '',
      problemDescription: '',
      briefDescription: '',
      pteGranted: 'no',
      petsOrChildrenPresent: 'no',
      requestorPhoneNumber: tenantDetails?.requestorPhoneNumber || '',
      requestorEmail: tenantDetails?.requestorEmail || '',
      alternativePhoneNumber: tenantDetails?.alternativePhoneNumber || '',
      alternativePhoneNumberForText:
        tenantDetails?.alternativePhoneNumberForText || '',
      alternativeEmail: tenantDetails?.alternativeEmail || '',
      accessNotes: tenantDetails?.accessNotes || '',
      address: tenantDetails?.address || '',
      unitCode: tenantDetails?.unitCode || '',
      propertyCode: tenantDetails?.neighborhood || '',
      leaseholder: tenantDetails?.requestorName || '',
    },
    enableReinitialize: true,
    validate: (formData) => {
      const errors = {};
      if (
        !formData.categorization ||
        !formData.subCategory ||
        !formData.prioritization ||
        !formData.pteGranted ||
        !formData.petsOrChildrenPresent
      ) {
        errors.name = 'Required';
      }
      return errors;
    },
    onSubmit: (formData) => {
      const woRequestBody = {
        ...formData,
        tenantCode: tenantDetails?.tenantCode,
        requestorName: tenantDetails?.requestorName,
      };
      placeWO(woRequestBody);
    },
  });

  const handleSanitizedSubmit = () => {
    formik.setFieldValue('technicianNotes', formik.values.technicianNotes.replace(/[',/{}_*<>:;]/g, ''));
    formik.setFieldValue('problemDescription', formik.values.problemDescription.replace(/[',/{}_*<>:;]/g, ''));
    formik.setFieldValue('accessNotes', formik.values.accessNotes.replace(/[',/{}_*<>:;]/g, ''));
    formik.setFieldValue('briefDescription', formik.values.briefDescription.replace(/[',/{}_*<>:;]/g, ''));
    formik.handleSubmit();
  };

  useEffect(() => {
    if (formik.values.accessNotes) {
      const formattedAccessNotes = formik.values.accessNotes.split(';').map((note) => note.trim()).join('\n');
      formik.setFieldValue('accessNotes', formattedAccessNotes);
    }
    if (formik.values.prioritization === PriorityConstants.Emergency) {
      formik.setFieldValue('pteGranted', 'yes');
    }
  }, [formik.values.prioritization]);

  return (
    <>
      <Modal
        open={isManualFormVisible}
        aria-labelledby='parent-modal-title'
        aria-describedby='parent-modal-description'
        key='summary'
      >
        <div className='submit-popup-container'>
          <form
            className='form-element'
            onSubmit={(e) => {
              e.preventDefault();
              setIsConfirmationModalVisible(true);
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '60%',
                height: '90%',
                bgcolor: 'background.paper',
                boxShadow: 24,
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 2,

                minWidth: '500px',
                overflowX: 'scroll',
              }}
            >
              <ModalClose
                onClick={() => {
                  if (!loading) {
                    formik.resetForm();
                    onClose();
                  }
                }}
                color='#434343'
              />
              <Box
                sx={{
                  overflow: 'auto',
                  flexGrow: 1,
                  p: 4,
                  flexDirection: 'column',
                  display: 'flex',
                }}
              >
                <Box
                  display='flex'
                  justifyContent='flex-start'
                  marginBottom={2}
                >
                  <Typography
                    id='modal-modal-title'
                    color='#52663c'
                    fontSize={24}
                  >
                    Manual WO Submission Form
                  </Typography>
                </Box>
                <Box display='flex'>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Autocomplete
                        fullWidth
                        autoComplete
                        autoFocus
                        autoHighlight
                        options={Object.keys(CategoryAndSubcategoryJSON)}
                        id='category'
                        value={formik.values.categorization}
                        onChange={(event, newValue) => {
                          formik.setFieldValue('categorization', newValue);

                          // if the category is cleared or new category is selected from the list
                          if (!newValue || event.type === 'click') {
                            formik.setFieldValue('subCategory', '');
                          }
                        }}
                        readOnly={loading}
                        disabled={loading}
                        renderInput={(params) => (
                          <TextField
                            color="success"
                            required
                            fullWidth
                            {...params}
                            label='Category'
                            variant='standard'
                            autoFocus
                            name='categorization'
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        fullWidth
                        options={
                          CategoryAndSubcategoryJSON[
                            formik.values.categorization
                          ] || []
                        }
                        value={formik.values.subCategory}
                        id='sub-category'
                        onChange={(event, newInputValue) => {
                          // only if the event is valid, update the sub-category value
                          // event will be null if the value is updated using formik.setFieldValue
                          // sub-category is updated using setFieldValue when category is updated
                          formik.setFieldValue('subCategory', newInputValue);
                        }}
                        disabled={formik.values.categorization === ''}
                        readOnly={loading}
                        renderInput={(params) => (
                          <TextField
                            required
                            color="success"
                            fullWidth
                            {...params}
                            label='Sub Category'
                            variant='standard'
                            name='subCategory'
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        id='priority'
                        color="success"
                        fullWidth
                        select
                        name='prioritization'
                        variant='standard'
                        label='Priority'
                        value={formik.values.prioritization}
                        disabled={loading}
                        onChange={formik.handleChange}
                        inputProps={{
                          readOnly: loading,
                        }}
                      >
                        {Object.keys(PriorityConstants).map((priority) => (
                          <MenuItem
                            key={priority}
                            value={PriorityConstants[priority]}
                          >
                            {priority}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        onChange={formik.handleChange}
                        color="success"
                        id='brief-description'
                        name='briefDescription'
                        label='Brief Description'
                        variant='standard'
                        value={formik.values.briefDescription}
                        disabled={loading}
                        required
                        inputProps={{
                          maxLength: maxBriefDescription,
                          readOnly: loading,
                        }}
                        helperText={`${
                          formik.values.briefDescription?.length || 0
                        } / ${maxBriefDescription}`}
                        FormHelperTextProps={{
                          sx: {
                            marginLeft: 0,
                            marginRight: 0,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        onChange={formik.handleChange}
                        onInputChange={formik.values.problemDescription}
                        value={formik.values.problemDescription}
                        color="success"
                        id='problem-description'
                        name='problemDescription'
                        label='Problem Description'
                        variant='filled'
                        required
                        inputProps={{
                          maxLength: maxProblemDescription,
                          readOnly: loading,
                        }}
                        FormHelperTextProps={{
                          sx: {
                            marginLeft: 0,
                            marginRight: 0,
                          },
                        }}
                        helperText={`${
                          formik.values.problemDescription?.length || 0
                        } / ${maxProblemDescription}`}
                        rows={4}
                        multiline
                        disabled={loading}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.technicianNotes}
                        onInputChange={formik.values.technicianNotes}
                        color="success"
                        id='technician-notes'
                        name='technicianNotes'
                        label='Technician Notes'
                        disabled={loading}
                        variant='filled'
                        inputProps={{
                          maxLength: maxTechnicianNotes,
                          readOnly: loading,
                        }}
                        FormHelperTextProps={{
                          sx: {
                            marginLeft: 0,
                            marginRight: 0,
                          },
                        }}
                        helperText={`${
                          formik.values.technicianNotes?.length || 0
                        } / ${maxTechnicianNotes}`}
                        rows={4}
                        multiline
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.accessNotes}
                        onInputChange={formik.values.accessNotes}
                        color="success"
                        id='access-notes'
                        name='accessNotes'
                        label='Access Notes'
                        variant='filled'
                        disabled={loading}
                        inputProps={{
                          maxLength: maxAccessNotesLength,
                        }}
                        FormHelperTextProps={{
                          sx: {
                            marginLeft: 0,
                            marginRight: 0,
                          },
                        }}
                        helperText={`${
                          formik.values.accessNotes?.length || 0
                        } / ${maxAccessNotesLength}`}
                        rows={4}
                        multiline
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id='address'
                        fullWidth
                        value={formik.values.address}
                        color="success"
                        variant='standard'
                        label='Address'
                        disabled
                        inputProps={{
                          readOnly: loading,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id='neighborhood'
                        fullWidth
                        value={formik.values.propertyCode}
                        color="success"
                        variant='standard'
                        label='Neighborhood Code'
                        disabled
                        inputProps={{
                          readOnly: loading,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id='lease-holder-name'
                        fullWidth
                        value={formik.values.leaseholder}
                        color="success"
                        variant='standard'
                        label='Lease Holder Name'
                        disabled
                        inputProps={{
                          readOnly: loading,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id='yardi-unit-cde'
                        fullWidth
                        value={formik.values.unitCode}
                        color="success"
                        variant='standard'
                        disabled
                        inputProps={{
                          readOnly: loading,
                        }}
                        label='Yardi Unit Code'
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id='phone'
                        fullWidth
                        value={formik.values.requestorPhoneNumber}
                        color="success"
                        variant='standard'
                        disabled
                        label='Phone'
                        inputProps={{
                          readOnly: loading,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id='email'
                        fullWidth
                        value={formik.values.requestorEmail}
                        color="success"
                        variant='standard'
                        disabled
                        label='Email'
                        inputProps={{
                          readOnly: loading,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl id='pte-granted'>
                        <FormLabel color='success'>Permission to enter granted?</FormLabel>
                        <RadioGroup
                          name='pteGranted'
                          value={formik.values.pteGranted}
                          onChange={formik.handleChange}
                          color='success'
                        >
                          <FormControlLabel
                            control={<Radio color='success' disabled={loading} />}
                            color='success'
                            label='Yes'
                            value='yes'
                          />
                          <FormControlLabel
                            color='success'
                            control={
                              <Radio
                                color='success'
                                disabled={
                                  formik.values.prioritization ===
                                    PriorityConstants.Emergency || loading
                                }
                              />
                            }
                            label='No'
                            value='no'
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl id='pets-or-children-present'>
                        <FormLabel color='success'>Pets/ Children present?</FormLabel>
                        <RadioGroup
                          name='petsOrChildrenPresent'
                          value={formik.values.petsOrChildrenPresent}
                          onChange={formik.handleChange}
                          color='success'
                        >
                          <FormControlLabel
                            value='yes'
                            color='success'
                            control={<Radio color='success' disabled={loading} />}
                            label='Yes'
                          />
                          <FormControlLabel
                            value='no'
                            color='success'
                            control={<Radio color='success' disabled={loading} />}
                            label='No'
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                p={2}
                sx={{ borderTop: '1px solid #e0e0e0' }}
                gap={1}
              >
                <LoadingButton
                  variant='contained'
                  type='submit'
                  color='success'
                  loading={loading}
                  sx={{ borderRadius: 2, textTransform: 'none' }}
                >
                  <span>Create WO</span>
                </LoadingButton>
              </Box>
            </Box>
          </form>
        </div>
      </Modal>
      <ConfirmationModal
        open={isConfirmationModalVisible}
        onClose={() => setIsConfirmationModalVisible(false)}
        onSubmit={handleSanitizedSubmit}
      />
    </>
  );
}

ManualForm.propTypes = {
  isManualFormVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  placeWO: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  tenantDetails: PropTypes.shape({
    requestorPhoneNumber: PropTypes.string,
    requestorEmail: PropTypes.string,
    address: PropTypes.string,
    unitCode: PropTypes.string,
    propertyCode: PropTypes.string,
    leaseholder: PropTypes.string,
    tenantCode: PropTypes.number,
    requestorName: PropTypes.string,
    alternativePhoneNumber: PropTypes.string,
    alternativePhoneNumberForText: PropTypes.string,
    alternativeEmail: PropTypes.string,
    accessNotes: PropTypes.string,
    neighborhood: PropTypes.string,
  }).isRequired,
};

export default ManualForm;
