/* eslint-disable comma-dangle */
/* eslint-disable no-param-reassign */
export const getChatResponse = (url, message, value) => {
  const csrftoken = document.cookie
    .split(';')
    .filter((item) => item.trim().startsWith('csrftoken'))[0]
    .split('=')[1];
  return fetch(`${url}?value=${encodeURI(value).toString()}`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': `${csrftoken}`,
    },
    credentials: 'include',
    body: JSON.stringify({ message })
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw response;
    })
    .catch((err) => {
      console.log('error', err);
      throw err;
    });
};
export const saveInfoForm = (body) => {
  const csrftoken = document.cookie
    .split(';')
    .filter((item) => item.trim().startsWith('csrftoken'))[0]
    .split('=')[1];
  const accesstoken = document.cookie
    .split(';')
    .filter((item) => item.trim().startsWith('access_token'))[0]
    .split('=')[1];
  return fetch(
    `${process.env.REACT_APP_WO_BACKEND_URL}/chat_bot_rasa/be/info/`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
        'X-CSRFToken': `${csrftoken}`,
        access_token: `Bearer ${accesstoken}`,
      },
      credentials: 'include',
      body: JSON.stringify(body),
    }
  ).then((response) => response.json());
};

export const getAddressSearchResponse = async (query) => {
  let fetchData;

  const csrftoken = document.cookie
    .split(';')
    .filter((item) => item.trim().startsWith('csrftoken'))[0]
    .split('=')[1];

  await fetch(
    `${process.env.REACT_APP_WO_BACKEND_URL}/chat_bot_rasa/be/search_address/?query=${query}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
        'X-CSRFToken': `${csrftoken}`,
      },
    }
  ).then((response) => {
    fetchData = response.json();
  });
  return fetchData;
};

export const chatInfoForm = (body) => {
  const csrftoken = document.cookie
    .split(';')
    .filter((item) => item.trim().startsWith('csrftoken'))[0]
    .split('=')[1];
  return fetch(
    `${process.env.REACT_APP_WO_BACKEND_URL}/chat_bot_rasa/be/webhook/`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
        'X-CSRFToken': `${csrftoken}`,
      },
      credentials: 'include',

      body: JSON.stringify(body),
    }
  ).then((response) => response.json());
};

export const placeWo = (body) => {
  const csrftoken = document.cookie
    .split(';')
    .filter((item) => item.trim().startsWith('csrftoken'))[0]
    .split('=')[1];
  return fetch(
    `${process.env.REACT_APP_WO_BACKEND_URL}/chat_bot_rasa/be/create_wo`,
    {
      method: 'POST',

      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
        'X-CSRFToken': `${csrftoken}`,
      },
      credentials: 'include',

      body: JSON.stringify(body),
    }
  )
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      }
      throw await response.json();
    })
    .catch((err) => {
      throw new Error(
        err.message ? JSON.stringify(err.message) : JSON.stringify(err)
      );
    });
};

export const editWo = (body) => {
  const csrftoken = document.cookie
    .split(';')
    .filter((item) => item.trim().startsWith('csrftoken'))[0]
    .split('=')[1];

  return fetch(
    `${process.env.REACT_APP_WO_BACKEND_URL}/chat_bot_rasa/be/edit_wo`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
        'X-CSRFToken': `${csrftoken}`,
      },
      credentials: 'include',
      body: JSON.stringify(body),
    }
  )
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      }
      throw await response.json();
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err));
    });
};

export const getLeaseholderResponse = async (yardiUnitCode) => {
  let fetchData;
  const csrftoken = document.cookie
    .split(';')
    .filter((item) => item.trim().startsWith('csrftoken'))[0]
    .split('=')[1];
  await fetch(
    `${process.env.REACT_APP_WO_BACKEND_URL}/chat_bot_rasa/be/lease_holders?unit_code=${yardiUnitCode}`,
    {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
        'X-CSRFToken': `${csrftoken}`,
      },
      credentials: 'include',
    }
  ).then((response) => {
    fetchData = response.json();
  });
  return fetchData;
};

export const getAllCounts = () => {
  const csrftoken = document.cookie
    .split(';')
    .filter((item) => item.trim().startsWith('csrftoken'))[0]
    .split('=')[1];

  return fetch(
    `${process.env.REACT_APP_WO_BACKEND_URL}/chat_bot_rasa/be/counts`,
    {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
        'X-CSRFToken': `${csrftoken}`,
      },
      credentials: 'include',
    }
  ).then((response) => response.json());
};
