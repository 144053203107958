import './LoginPage.css';

/* eslint-disable operator-linebreak */
/* eslint-disable jsx-quotes */
/* eslint-disable react/jsx-filename-extension */
import { Button } from '@mui/material';
import React from 'react';
import logo from '../assets/Sarge-Login-Logo.png';

function LoginPage() {
  function clearAllCookies() {
    const cookies = document.cookie.split('; ');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    }
  }
  const handleLogin = () => {
    localStorage.clear();
    sessionStorage.clear();
    clearAllCookies();
    window.location.href = `https://sso.connect.pingidentity.com/sso/as/authorization.oauth2?client_id=${process.env.REACT_APP_PINGONE_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_PINGONE_REDIRECT_URI}&scope=openid`;
  };
  return (
    <div className="login-container">
      <div className="login-box">
        <img
          src={logo}
          alt="Sarge-Logo"
          style={{ marginTop: '10px', height: '200px', width: 'auto' }}
        />
        <Button
          variant="contained"
          sx={{
            borderRadius: 1,
            textTransform: 'none',
            width: '100%',
            fontSize: 16,
            fontWeight: 'bold',
          }}
          color="success"
          onClick={() => {
            handleLogin();
          }}
        >
          Login
        </Button>
      </div>
    </div>
  );
}

export default LoginPage;
