/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable comma-dangle */
/* eslint-disable jsx-quotes */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import {
  Box,
  Button,
  Checkbox,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { ModalClose } from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import { startCase } from 'lodash';

function WOComparisonTable({
  isModalVisible,
  onClose,
  selectedWODetail,
  currentWODetail,
  handleCreateWO,
  woType,
}) {
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const IGNORE_FIELDS_IN_CURRENT_WO_TABLE = [
    'wo_created',
    'recipient_id',
    'version',
    'status',
    'progress',
  ];

  return (
    <Modal open={isModalVisible} style={{ overflow: 'scroll' }}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '70%',
          height: '90%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 2,

          minWidth: '500px',
          overflowX: 'scroll',
        }}
      >
        <ModalClose
          onClick={(e) => {
            e.preventDefault();
            setChecked(false);
            onClose();
          }}
          color='#434343'
        />
        <Box
          display='flex'
          justifyContent='flex-start'
          gap={10}
          overflow='auto'
          padding='5%'
          sx={{ marginTop: 1 }}
        >
          <TableContainer
            component={Paper}
            sx={{
              boxShadow: 'none',
              minWidth: '300px',
              overflow: 'scroll',
            }}
          >
            <Table stickyHeader>
              <TableHead sx={{ border: 1, borderColor: 'red' }}>
                <TableRow>
                  <TableCell align='center' colSpan={2} sx={{ fontSize: 24 }}>
                    Potential
                    {' '}
                    {woType}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedWODetail.map((eachRow) => (
                  <TableRow key={eachRow.key + eachRow.value}>
                    <TableCell
                      component='th'
                      scope='row'
                      sx={{ backgroundColor: '#cdd9bd', color: '#52663c' }}
                    >
                      {eachRow.key}
                    </TableCell>
                    <TableCell
                      align='right'
                      sx={{ backgroundColor: '#f4f6ef', color: '#52663c' }}
                    >
                      {eachRow.value}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer
            component={Paper}
            sx={{
              boxShadow: 'none',
              minWidth: '300px',
              overflow: 'scroll',
            }}
          >
            <Table stickyHeader aria-label='customized table'>
              <TableHead>
                <TableRow>
                  <TableCell align='center' colSpan={2} sx={{ fontSize: 24 }}>
                    Current WO
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentWODetail
                  .filter((eachRow) => !IGNORE_FIELDS_IN_CURRENT_WO_TABLE.includes(eachRow.key))
                  .map((eachRow) => (
                    <TableRow key={eachRow.key + eachRow.value}>
                      <TableCell
                        component='th'
                        scope='row'
                        sx={{ backgroundColor: '#cdd9bd', color: '#52663c' }}
                      >
                        {startCase(eachRow.key)}
                      </TableCell>
                      <TableCell
                        align='right'
                        sx={{ backgroundColor: '#f4f6ef', color: '#52663c' }}
                      >
                        {eachRow.value}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          p={2}
          sx={{ borderTop: '1px solid #e0e0e0' }}
          gap={1}
        >
          <Button
            variant='contained'
            type='submit'
            color='success'
            sx={{ borderRadius: 2, textTransform: 'none' }}
            onClick={() => {
              handleCreateWO(checked);
              setChecked(false);
            }}
          >
            {woType !== 'Reopen' ? 'Continue to Submit WO' : 'Continue to Submit WO'}
          </Button>
          {/* {woType !== 'Reopen' && ( */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Checkbox color='success' checked={checked} onChange={handleChange} />
            <Typography>
              {woType === 'Duplicate'
                ? 'Mark Potential Duplicate as Duplicate'
                : woType === 'Repeat'
                ? 'Mark Potential Repeat as Repeat'
                : 'Re-Open closed WO'}
            </Typography>
          </Box>
          {/* )} */}
        </Box>
      </Box>
    </Modal>
  );
}

WOComparisonTable.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedWODetail: PropTypes.object.isRequired,
  currentWODetail: PropTypes.object.isRequired,
  handleCreateWO: PropTypes.func.isRequired,
  woType: PropTypes.string.isRequired,
};

export default WOComparisonTable;
