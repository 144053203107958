/* eslint-disable jsx-quotes */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable comma-dangle */
import './RedirectPage.css';

import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function RedirectPage({ handleLogin }) {
  const navigate = useNavigate();
  const sendTokenToBackend = async (code) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_WO_BACKEND_URL}/chat_bot_rasa/be/token_auth/`,
        { code },
        {
          withCredentials: true,
        }
      );

      if (response.data.status === 'success') {
        handleLogin();
        navigate('/chat');
      } else {
        navigate('/login');
      }
    } catch (error) {
      navigate('/login');
    }
  };
  useEffect(() => {
    // Extract the code from the URL query parameters
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    // Send the code to backend
    sendTokenToBackend(code);
  }, []);

  return (
    <div className='loader-container-ani'>
      <div className='lds-roller'>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}
RedirectPage.propTypes = {
  handleLogin: PropTypes.func.isRequired,
};

export default RedirectPage;
