import PropTypes from 'prop-types';
import React from 'react';
import Avatar from 'react-avatar';
import { getCookieValue } from '../util/cookies';
import sargeLogo from '../assets/Sarge_Logo.png';
// Step 1: Import PropTypes
function AvatarS({ user = 0 }) {
  return (
    <div>
      {user ? (
        <Avatar
          // eslint-disable-next-line react/jsx-boolean-value
          round={true}
          src={sargeLogo}
          size="40"
          color="#e0e0e0"
        />
      ) : (
        <Avatar
          name={getCookieValue('username')}
          // eslint-disable-next-line react/jsx-boolean-value
          round={true}
          size="40"
          color="#e5ebdc"
          fgColor="#3a462e"
        />
      )}
    </div>
  );
}
AvatarS.propTypes = {
  user: PropTypes.number.isRequired, // Assuming 'user' is always required and a number
};

export default AvatarS;
