import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RedirectToLogin, SetAccessToken, SetCsrfToken } from '../util/cookies';

function LogoutButton({ setIsLoggedIn }) {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      // Make a request to the logout endpoint on the backend
      const accessToken = SetAccessToken(navigate);
      const csrfToken = SetCsrfToken(navigate);
      if (accessToken && csrfToken) {
        const accesstoken = accessToken.split('=')[1];
        const csrftoken = csrfToken.split('=')[1];
        const response = await fetch(
          `${process.env.REACT_APP_WO_BACKEND_URL}/chat_bot_rasa/be/logout/`,
          {
            method: 'POST',
            credentials: 'include', // Include cookies in the request
            headers: {
              'X-CSRFToken': `${csrftoken}`,
              access_token: `Bearer ${accesstoken}`,
            },
          },
          { withCredentials: true },
        );
        if (response.status === 200) {
          // Clear all items from localStorage
          localStorage.clear();

          // Clear all items from sessionStorage
          sessionStorage.clear();

          // Set isLoggedIn to false to update the login state
          // You can use the state management library (e.g., Redux) for a global state
          setIsLoggedIn(false);

          // Get the logout URL from the response JSON
          // const { logout_url } = await response.json();

          // window.open(logout_url, "_blank");
          window.open(
            'https://sso.connect.pingidentity.com/sso/initslo',
            '_blank',
          );

          // Redirect the user to the logout URL received from the backend
          // window.location.href = logout_url;
          navigate('/login');
        } else {
          console.error('Logout failed:', response.statusText);
        }
      } else RedirectToLogin(navigate);
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <div className="logout-button-container">
      <button type="button" className="logout-button" onClick={handleLogout}>
        Logout
      </button>
    </div>
  );
}

LogoutButton.propTypes = {
  setIsLoggedIn: PropTypes.func.isRequired,
};

export default LogoutButton;
