/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-quotes */
import { Box, Button, Grid, Modal, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import React from 'react';

function PostCreationModal({
  open,
  body,
  exitSession,
  title,
  primaryButtonText,
  errorButtonText,
  primaryButtonAction,
  icon,
}) {
  return (
    <Modal open={open}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '30%',
          height: '20%',
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '1%',
          textAlign: 'center',

          minWidth: '500px',
          overflow: 'scroll',
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {icon}
          </Grid>
          <Grid item xs={12}>
            <Typography>{title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: 'bold', fontSize: 20 }}>
              {body}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant='contained'
              color='success'
              sx={{ borderRadius: 2, textTransform: 'none', width: '100%' }}
              onClick={primaryButtonAction}
            >
              {primaryButtonText}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant='contained'
              sx={{ borderRadius: 2, textTransform: 'none', width: '100%' }}
              onClick={exitSession}
              color='error'
            >
              {errorButtonText}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

PostCreationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  body: PropTypes.string.isRequired,
  exitSession: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  primaryButtonText: PropTypes.string.isRequired,
  errorButtonText: PropTypes.string.isRequired,
  primaryButtonAction: PropTypes.func.isRequired,
  icon: PropTypes.any.isRequired,
};

export default PostCreationModal;
