/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-quotes */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-filename-extension */
import PropTypes from 'prop-types';
// App.js File
import React from 'react';

function ChatOptions({ options, onClick }) {
  return options.length > 0 ? (
    <div className='chat-options'>
      {options.map((option, index) => (
        <label
          key={index}
          onClick={() => {
            const { label, value, woType } = option;
            onClick(label, value, woType, option);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onClick(option.label, option.value);
            }
          }}
          role='none'
          className={`${option.woType}-wo-bubble`}
        >
          {option.label}
        </label>
      ))}
    </div>
  ) : (
    <span />
  );
}
ChatOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func.isRequired, // Assuming 'onClick' is a function
  // You can add more prop validations as needed
};
export default ChatOptions;
