/* eslint-disable brace-style */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable indent */
/* eslint-disable function-paren-newline */
/* eslint-disable curly */
/* eslint-disable nonblock-statement-body-position */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable import/order */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-quotes */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable operator-linebreak */
import './chatBot.css';

import { Autocomplete, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import {
  editWo,
  getChatResponse,
  placeWo,
} from '../../services/chatBotService';

import AvatarS from '../avatar';
import ChatOptions from '../chatOptions';
import ChatText from '../chatText';
import ErrorIcon from '@mui/icons-material/Error';
import LoginForm from '../LoginForm/loginForm';
import ManualForm from '../ManualForm/manualForm';
import ManualFormIcon from '@mui/icons-material/Article';
import PostCreationModal from '../Modals/postCreationModal';
import PriorityConstants from '../../constants/priorityConstants';
import ProgressBar from '@ramonak/react-progress-bar';
import PropTypes from 'prop-types';
import RefreshIcon from '@mui/icons-material/Refresh';
import RefreshModal from '../Modals/refreshModal';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import SummaryForm from '../SummaryForm/summaryForm';
import WOComparisonTable from '../WOComparisonTable/woComparisonTable';
import WarningIcon from '@mui/icons-material/Warning';
import { isEmpty } from 'lodash';
import sargeLogo from '../../assets/sarge-hunt-logo.png';

function ChatBot({ url }) {
  const [message, setMessage] = useState('');
  const [payload, setPayload] = useState(undefined);
  const [messageLabel, setMessageLabel] = useState(undefined);

  const [messageList, updateMessageList] = useState([]);
  const [selectedWO, setSelectedWO] = useState({});
  const [showPostConversation, setShowPostConversation] = useState(true);
  const [currentWODetails, setCurrentWODetails] = useState([]);
  const [selectedWODetails, setSelectedWODetails] = useState([]);
  const [unitCode, setUnitCode] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoginFormSubmitted, setIsLoginFormSubmitted] = useState(false);

  const [potentialWOList, updatePotentialWOList] = useState([]);
  const [optionList, updateOptionList] = useState([]);
  const [optionsLabels, setOptionsLabels] = useState([]);
  const [showPopup, setShowPopup] = useState(false); // Added state for showing popup
  const [showSubmitPopup, setShowSubmitPopup] = useState(false);
  const [isFormDisplay, setIsFormDisplay] = useState(false);
  const [dialogFlow, setDialogFlow] = useState({});
  const [leaseholder, setLeaseholder] = useState('');
  const [isAddressForm, setAddressForm] = useState(false);
  const [progressValue, setProgressData] = useState(0);
  const [woId, setWoId] = useState();
  const [tenantDetails, setTenantDetails] = useState({});
  const [isManualFormVisible, setIsManualFormVisible] = useState(false);
  const [woDetails, setWoDetails] = useState({});
  const [isSummaryFormVisible, setIsSummaryFormVisible] = useState(false);
  const [markPotentialWO, setPotentialWO] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiMessage, setApiMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isPostCreationModalVisible, setIsPostCreationModalVisible] =
    useState(false);
  const [isRefreshPopupVisible, setIsRefreshPopupVisible] = useState(false);
  const [dontCreateWO, setDontCreateWO] = useState(false);
  const [displayViewSummaryButton, setDisplayViewSummaryButton] =
    useState(false);

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  const messageElementRef = useRef(null);

  const scrollToBottom = () => {
    // delay the scroll
    setTimeout(() => {
      if (messageElementRef.current) {
        messageElementRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messageList.length, displayViewSummaryButton, potentialWOList.length]);

  const addMessageToChat = async (msg, user = 0, index = 0) =>
    new Promise((resolve) => {
      // Simulate async operation, e.g., network request, timeout, etc.
      setTimeout(() => {
        updateMessageList((arr) => [...arr, { user, msg, index }]);
        resolve(); // Resolve the promise after the operation
      }, 100); // Adjust timeout as needed
    });

  const addPotentialWOToList = async (items) =>
    // Simulate async operation, e.g., network request, timeout, etc.
    new Promise((resolve) => {
      setTimeout(() => {
        updatePotentialWOList(items);
        resolve(); // Resolve the promise after the operation
      }, 100); // Adjust timeout as needed
    });

  const processChatResponse = (messageText, messageValue) => {
    getChatResponse(url, messageText, messageValue)
      .then((response) => {
        response = response.output?.forEach(async (item, index) => {
          if (item.type === 'text') {
            // if the text value has "technician_notes" attribute then dont add
            // the value to chat message
            if (
              Object.keys(item.value).find(
                (value) => value === 'technician_notes'
              )
            ) {
              if (!item.value?.wo_created) {
                setIsPostCreationModalVisible(true);
                setDontCreateWO(true);
              } else {
                setDialogFlow(item.value);
                setDisplayViewSummaryButton(true);
              }
            } else {
              await addMessageToChat(item.value, 1, index);
            }
          } else if (item.type === 'payload') {
            setPayload(item.value);
          } else if (item.type === 'wo') {
            setIsFormDisplay(true);
            await addPotentialWOToList(item.options);
          } else if (item.type === 'option') {
            updateOptionList(item.options);
            const itemOptionsLabels = item.options.map(
              (optionData) => optionData.label
            );
            setOptionsLabels(itemOptionsLabels);
          } else if (item.type === 'percentage') {
            setProgressData(item.percentage);
          } else if (item.type === 'current_wo') {
            setDialogFlow(item.value);
            const values = Object.entries(item.value);

            // Define the desired order
            const desiredOrder = [
              'category',
              'sub_category',
              'priority',
              'problem_description',
              'technician_notes',
            ];

            // Sort the array based on the desired order
            const sortedValue = values.sort((a, b) => {
              const indexA = desiredOrder.indexOf(a[0]);
              const indexB = desiredOrder.indexOf(b[0]);

              if (indexA === -1) return 1; // Move items not in desiredOrder to the end
              if (indexB === -1) return -1; // Move items not in desiredOrder to the end

              return indexA - indexB;
            });

            const woDetailData = sortedValue.map((value) => ({
              key: value[0],
              value: value[1],
            }));
            setCurrentWODetails(woDetailData);
          }
        });
      })
      .catch((err) => {
        if (err.status === 404) setApiMessage('URL not found');
        else {
          err
            .json()
            .then((error) =>
              setApiMessage(
                error.message ? error.message : 'Something went wrong'
              )
            );
        }
        setIsSuccess(false);
        setIsPostCreationModalVisible(true);
      });
  };

  const openSummaryForm = () => {
    setWoDetails({
      requestorName: tenantDetails?.requestorName,
      requestorPhoneNumber: tenantDetails?.requestorPhoneNumber,
      requestorEmail: tenantDetails?.requestorEmail,
      propertyCode: tenantDetails?.neighborhood,
      tenantCode: tenantDetails?.tenantCode,
      address: tenantDetails?.address,
      unitCode: tenantDetails?.unitCode,
      leaseholder: tenantDetails?.requestorName,
      alternativeEmail: tenantDetails?.alternativeEmail,
      alternativePhoneNumber: tenantDetails?.alternativePhoneNumber,
      alternativePhoneNumberForText:
        tenantDetails?.alternativePhoneNumberForText,
      accessNotes: tenantDetails?.accessNotes,
      categorization: dialogFlow.category,
      subCategory: dialogFlow.sub_category,
      prioritization: PriorityConstants[dialogFlow.priority],
      technicianNotes: dialogFlow.technician_notes,
      problemDescription: dialogFlow.problem_description,
    });
  };

  useEffect(() => {
    if (!isEmpty(woDetails) && woDetails?.categorization)
      setIsSummaryFormVisible(true);
  }, [woDetails]);

  useEffect(() => {
    if (isLoginFormSubmitted) {
      processChatResponse(`hi ${unitCode}`, 0);
    }
  }, [isLoginFormSubmitted]);

  useEffect(() => {
    setDisplayViewSummaryButton(false);
    const tenantDetailsFromSession = JSON.parse(
      sessionStorage.getItem('tenantDetails')
    );
    if (
      (tenantDetailsFromSession &&
        Object.keys(tenantDetailsFromSession).length <= 0) ||
      !tenantDetailsFromSession
    ) {
      setAddressForm(true);
    }

    if (
      tenantDetailsFromSession &&
      Object.keys(tenantDetailsFromSession).length &&
      !unitCode
    ) {
      setLeaseholder(tenantDetailsFromSession.requestorName);
      setUnitCode(tenantDetailsFromSession.unitCode);
      processChatResponse(
        `hi ${JSON.parse(sessionStorage.getItem('tenantDetails')).unitCode}`,
        0
      );
    }
  }, []);

  useEffect(() => {
    let tenantDetailsFromSession = sessionStorage.getItem('tenantDetails');
    if (tenantDetailsFromSession) {
      tenantDetailsFromSession = JSON.parse(tenantDetailsFromSession);

      setTenantDetails(tenantDetailsFromSession);

      if (!leaseholder && tenantDetailsFromSession.requestorName) {
        setLeaseholder(tenantDetailsFromSession.requestorName);
      }

      setWoDetails({
        ...woDetails,
      });
    }
  }, [sessionStorage.getItem('tenantDetails')]);

  const resetChat = () => {
    updateMessageList([]); // Clear all previous messages
    updatePotentialWOList([]);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    addMessageToChat(message);
    setMessage('');
    updatePotentialWOList([]);
    const messageOption = optionList.find((option) => {
      if (option.label === message) {
        return option.value;
      }
      return undefined;
    });

    if (messageOption) {
      processChatResponse(messageOption.label, messageOption.value);
    } else {
      const sanitizedMessage = message.replace(/[',/{}<>:;]/g, '');
      processChatResponse(sanitizedMessage, payload);
    }
    updateOptionList([]);
    setOptionsLabels([]);
    setMessageLabel(undefined);
  };

  const onOptionClick = (option, value, woType, details) => {
    const selectedWoDetails = Object.entries(details);
    const filteredWoDetails = selectedWoDetails.filter(
      (woDetail) => woDetail[0] !== 'label' && woDetail[0] !== 'value'
    );
    const woDetailData = filteredWoDetails.map((woDetail) => ({
      key: woDetail[0],
      value: woDetail[1],
    }));
    setSelectedWODetails(woDetailData);
    setSelectedWO({
      label: option,
      value,
      isDuplicate: woType === 'Duplicate',
      woType,
    });
    setIsModalOpen(true);
  };

  const undoChatResponse = (value) => {
    processChatResponse(value, 0);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };
  const handleOptionChange = (event, value) => {
    if (value) {
      const messageOption = optionList.find((option) => {
        if (option.label === value) {
          return option.value;
        }
        return undefined;
      });
      if (messageOption) {
        setMessageLabel(messageOption.label);
      } else {
        setMessageLabel(undefined);
      }
      setMessage(value);
    }
  };
  const handleRefreshClick = () => {
    setIsRefreshPopupVisible(true);
  };

  const handleRefresh = () => {
    setShowPopup(false);
    resetChat(); // Call the function to reset the chat
    sessionStorage.removeItem('tenantDetails');
    window.location.reload(); // Call the API to start a new conversation
  };

  // Handle Repeat/Duplicate/Re-open WO (Update WO in Yardi)
  const handleDuplicateReopenWO = (woRequest) => {
    if (selectedWO.woType.toUpperCase() === 'DUPLICATE') {
      woRequest.problemDescription += `; Resident is trying to reach out. ${new Date().toLocaleString()}; `;
    }
    const editWoRequest = {
      ServiceRequestId: selectedWO.value,
      propertyCode: woRequest.propertyCode,
      tenantCode: woRequest.tenantCode,
      briefDescription: woRequest.briefDescription,
      prioritization: woRequest.prioritization,
      categorization: woRequest.categorization,
      subCategory: woRequest.subCategory,
      problemDescription: woRequest.problemDescription,
      accessNotes: woRequest.accessNotes,
      technicianNotes: woRequest.technicianNotes,
      woStatus: selectedWO.woType,
      pteGranted: woRequest.pteGranted,
      petsOrChildrenPresent: woRequest.petsOrChildrenPresent,
    };

    editWo(editWoRequest)
      .then((response) => {
        setWoId(response.ServiceRequestId);
        setShowSubmitPopup(true);
        setApiMessage(response.ServiceRequestId);
        setIsSuccess(true);
      })
      .catch((err) => {
        setApiMessage(err.message);
        setIsSuccess(false);
      })
      .finally(() => {
        setLoading(false);
        setIsPostCreationModalVisible(true);
      });
  };

  const newWo = (request) => {
    placeWo(request)
      .then((response) => {
        setWoId(response.ServiceRequestId);
        setShowSubmitPopup(true);

        setApiMessage(response.ServiceRequestId);
        setIsSuccess(true);
      })
      .catch((err) => {
        setApiMessage(err.message);
        setIsSuccess(false);
      })
      .finally(() => {
        setLoading(false);
        setIsPostCreationModalVisible(true);
      });
  };

  const createWO = (woRequest) => {
    setLoading(true);
    if (Object.keys(selectedWO).length && !isManualFormVisible) {
      if (
        (selectedWO.woType.toUpperCase() === 'DUPLICATE' ||
          selectedWO.woType.toUpperCase() === 'REOPEN') &&
        markPotentialWO
      ) {
        handleDuplicateReopenWO(woRequest);
      } else if (
        selectedWO.woType.toUpperCase() === 'REPEAT' &&
        markPotentialWO
      ) {
        woRequest.accessNotes += ` WO is a repeat of ${selectedWO.value}; `;
        woRequest.woType = 'Repeat';
        woRequest.parentWoId = selectedWO.value;
        newWo(woRequest);
      } else {
        newWo(woRequest);
      }
    } else {
      // Repeat or new WO creation
      newWo(woRequest);
    }
  };

  const startNewChat = () => {
    setIsFormDisplay(false);
    setShowSubmitPopup(false);
    resetChat(); // Call the function to reset the chat
    processChatResponse(
      `hi ${JSON.parse(sessionStorage.getItem('tenantDetails')).unitCode}`,
      0
    );
  };

  const endChat = () => {
    setShowSubmitPopup(false);
    resetChat(); // Call the function to reset the chat
    sessionStorage.removeItem('tenantDetails');
    window.location.reload();
  };

  const chatFormDisplay = (value, isDuplicate) => {
    setIsFormDisplay(value);
    setShowPostConversation(!isDuplicate);
  };

  const handleCreateWOInComparisonModal = (markPotentialAsWO) => {
    setIsModalOpen(false);
    setPotentialWO(markPotentialAsWO);

    // current access notes, technician notes, problem description and brief description
    let accessNotes = tenantDetails?.accessNotes;
    let technicianNotes = currentWODetails.find(
      (details) => details.key === 'technician_notes'
    ).value;
    let problemDescription = currentWODetails.find(
      (details) => details.key === 'problem_description'
    ).value;
    let briefDescription = '';

    if (markPotentialAsWO) {
      const previousWOProblemDescription = selectedWODetails.find(
        (details) => details.key === 'Problem Description'
      )?.value;

      const previousWOTechnicianNotes = selectedWODetails.find(
        (details) => details.key === 'Technician Notes'
      )?.value;

      const previousWOBriefDescription = selectedWODetails.find(
        (details) => details.key === 'Brief Description'
      )?.value;

      const previousWOAccessNotes = selectedWODetails.find(
        (details) => details.key === 'Access Notes'
      )?.value;

      if (selectedWO.woType.toUpperCase() === 'DUPLICATE') {
        problemDescription = previousWOProblemDescription;

        technicianNotes = previousWOTechnicianNotes;

        briefDescription = previousWOBriefDescription;

        accessNotes = previousWOAccessNotes;
      } else {
        accessNotes += `; WO is a repeat of ${
          selectedWODetails.find(
            (details) => details.key === 'Work Order Number'
          )?.value
        }.`;
      }
    }
    setWoDetails({
      requestorName: tenantDetails?.requestorName,
      requestorPhoneNumber: tenantDetails?.requestorPhoneNumber,
      requestorEmail: tenantDetails?.requestorEmail,
      propertyCode: tenantDetails?.neighborhood,
      tenantCode: tenantDetails?.tenantCode,
      address: tenantDetails?.address,
      unitCode: tenantDetails?.unitCode,
      leaseholder: tenantDetails?.requestorName,
      alternativeEmail: tenantDetails?.alternativeEmail,
      alternativePhoneNumber: tenantDetails?.alternativePhoneNumber,
      alternativePhoneNumberForText:
        tenantDetails?.alternativePhoneNumberForText,
      categorization: dialogFlow.category,
      subCategory: dialogFlow.sub_category,
      prioritization: PriorityConstants[dialogFlow.priority],
      accessNotes,
      technicianNotes,
      problemDescription,
      briefDescription,
    });
  };

  const getPostCreationModalTitle = () => {
    if (markPotentialWO && selectedWO.woType.toUpperCase() === 'DUPLICATE')
      return 'Work Order Updated';
    if (markPotentialWO && selectedWO.woType.toUpperCase() === 'REOPEN')
      return 'Work Order Re-Opened';
    if (dontCreateWO)
      return 'No work order needs to be created for this scenario.';
    if (isSuccess) return 'Work Order Created';
    return 'The Work Order was not created';
  };

  const getPrimaryButtonText = () => {
    if (dontCreateWO) return 'Want to create another WO?';
    if (isSuccess) return 'Want to create another WO?';
    return 'Create WO in Manual Form';
  };

  const handleExitSession = () => {
    setIsPostCreationModalVisible(false);
    setDisplayViewSummaryButton(false);
    setIsSummaryFormVisible(false);
    setIsManualFormVisible(false);
    setPotentialWO(false);
    setDontCreateWO(false);
    setApiMessage('');
    setIsSuccess(false);
    endChat();
  };

  const handleContinueSession = () => {
    setPotentialWO(false);
    setIsSummaryFormVisible(false);
    setDisplayViewSummaryButton(false);
    setIsManualFormVisible(false);
    setIsPostCreationModalVisible(false);
    setDontCreateWO(false);
    setApiMessage('');
    setIsSuccess(false);
    startNewChat();
  };

  const handleOpenManualForm = () => {
    setIsManualFormVisible(false);
    setIsPostCreationModalVisible(false);
    setIsSummaryFormVisible(false);
    setDisplayViewSummaryButton(false);
    setPotentialWO(false);
    setApiMessage('');
    setIsSuccess(false);
    setDontCreateWO(false);
    startNewChat();
    // Use setTimeout to allow the component to unmount and remount
    setTimeout(() => setIsManualFormVisible(true), 0);
  };

  const getPrimaryButtonAction = () => {
    if (dontCreateWO) return handleContinueSession;
    if (isSuccess) return handleContinueSession;
    return handleOpenManualForm;
  };

  const getIcon = () => {
    if (dontCreateWO) return <WarningIcon color="warning" fontSize="large" />;
    if (isSuccess) return <SuccessIcon color="success" fontSize="large" />;
    return <ErrorIcon color="error" fontSize="large" />;
  };

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <>
      <div className="chat-bot-container">
        <ProgressBar
          labelAlignment="left"
          isLabelVisible={progressValue >= 0}
          completed={progressValue || 0}
          className="progress-bar"
          labelClassName="progress-bar-label"
        />
        <div className="chat-window">
          <div className="chat-header">
            <div>
              <div>
                <img src={sargeLogo} alt="Logo" height={50} />
              </div>
              {leaseholder && (
                <div
                  style={{ marginTop: 2, fontSize: 12, fontStyle: 'italic' }}
                >
                  Current caller: {leaseholder}
                </div>
              )}
            </div>

            <div
              className="form-icon-container"
              onClick={() => {
                setIsManualFormVisible(true);
                setPotentialWO(false);
                setSelectedWO({});
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setIsManualFormVisible(true);
                  setPotentialWO(false);
                  setSelectedWO({});
                }
              }}
              role="presentation"
            >
              <Tooltip title="Manual Form" arrow>
                <ManualFormIcon color="primary" sx={{ fontSize: 20 }} />
              </Tooltip>
            </div>

            <div
              className="refresh-button"
              onClick={handleRefreshClick}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleRefreshClick();
                }
              }}
              tabIndex={0}
              role="button"
            >
              <Tooltip title="Refresh" arrow>
                <RefreshIcon color="primary" sx={{ fontSize: 20 }} />
              </Tooltip>
            </div>
          </div>
          <div
            className="chat-display"
            style={{
              height: 'calc(100% - 90px)',
            }}
          >
            <div className="chat-text-area">
              {messageList.map((chatMessage, index) => (
                <ChatText
                  key={index}
                  text={chatMessage.msg}
                  user={chatMessage.user}
                  chatFormDisplay={chatFormDisplay}
                  setDialogFlow={setDialogFlow}
                  openSummaryForm={() => openSummaryForm()}
                  leaseholder={leaseholder}
                  displayViewSummaryButton={
                    index === messageList.length - 1
                      ? displayViewSummaryButton
                      : false
                  }
                  messageCount={messageList.length}
                  avatar={
                    (chatMessage.index === 0 ||
                      chatMessage.msg.includes('Duplicate/Repeat/Reopen')) &&
                    chatMessage.user ? (
                      <AvatarS user={chatMessage.user} />
                    ) : (
                      <div style={{ width: '40px' }} />
                    )
                  }
                  index={index}
                  undoChatResponse={undoChatResponse}
                />
              ))}
              <ChatOptions options={potentialWOList} onClick={onOptionClick} />
              <span className="space-20" ref={messageElementRef} />
            </div>
            {!isFormDisplay && !displayViewSummaryButton && (
              <div>
                <span
                  style={{
                    fontStyle: 'italic',
                    fontFamily: 'Helvetica',
                    display: 'flex',
                    paddingBottom: '5px',
                  }}
                >
                  {optionList.length > 0
                    ? 'Choose from the list'
                    : 'Please type a response below'}
                </span>
                <form className="input-form" onSubmit={submitHandler}>
                  {optionList.length > 0 ? (
                    <Autocomplete
                      disablePortal
                      style={{ width: '100%' }}
                      autoComplete
                      autoFocus
                      autoHighlight
                      options={optionsLabels.sort()}
                      value={messageLabel}
                      onChange={handleOptionChange}
                      onInputChange={(event, newInputValue) => {
                        setMessage(newInputValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" autoFocus />
                      )}
                      ListboxProps={{
                        style: {
                          maxHeight: '175px',
                          overflow: 'auto',
                        },
                      }}
                    />
                  ) : (
                    <input
                      type="text"
                      value={message}
                      name="message"
                      autoFocus
                      onChange={handleMessageChange}
                      autoComplete="off"
                    />
                  )}
                  <input
                    type="submit"
                    value="Send"
                    className={`submit-input ${
                      (optionList.length
                        ? messageLabel && messageLabel === message
                        : message?.trim()) && 'submit-button'
                    }`}
                    disabled={
                      optionList.length
                        ? !(
                            messageLabel &&
                            messageLabel.length &&
                            messageLabel === message
                          )
                        : !(message && message?.trim())
                    }
                  />
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
      <LoginForm
        openForm={isAddressForm}
        closeForm={setAddressForm}
        setUnitCode={setUnitCode}
        setLoginFormSubmitted={setIsLoginFormSubmitted}
      />
      {isManualFormVisible && (
        <ManualForm
          isManualFormVisible={isManualFormVisible}
          onClose={() => {
            setIsManualFormVisible(false);
          }}
          tenantDetails={tenantDetails}
          loading={loading}
          placeWO={createWO}
        />
      )}
      {isSummaryFormVisible && (
        <SummaryForm
          isSummaryFormVisible={isSummaryFormVisible}
          duplicateWorkorderId={markPotentialWO ? selectedWO?.value || '' : ''}
          onClose={() => {
            setIsSummaryFormVisible(false);
          }}
          woType={markPotentialWO ? selectedWO?.woType || '' : ''}
          loading={loading}
          woDetail={woDetails}
          placeWO={createWO}
        />
      )}
      <WOComparisonTable
        isModalVisible={isModalOpen}
        onClose={handleModalCancel}
        selectedWODetail={selectedWODetails}
        currentWODetail={currentWODetails}
        handleCreateWO={handleCreateWOInComparisonModal}
        woType={selectedWO.woType}
      />
      {isPostCreationModalVisible && (
        <PostCreationModal
          open={isPostCreationModalVisible}
          body={apiMessage}
          isSuccess={isSuccess}
          title={getPostCreationModalTitle()}
          exitSession={handleExitSession}
          primaryButtonText={getPrimaryButtonText()}
          errorButtonText="Exit Session"
          primaryButtonAction={getPrimaryButtonAction()}
          icon={getIcon()}
        />
      )}
      <RefreshModal
        open={isRefreshPopupVisible}
        onClose={() => setIsRefreshPopupVisible(false)}
        onProceed={handleRefresh}
      />
    </>
  );
}
ChatBot.propTypes = {
  url: PropTypes.string.isRequired,
};

export default ChatBot;
