export const ClearAllCookies = () => {
  const cookies = document.cookie.split('; ');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
  }
};

export const RedirectToLogin = (navigate) => {
  navigate('/login');
  localStorage.clear();
  sessionStorage.clear();
  ClearAllCookies();
};

export const SetAccessToken = (navigate) => {
  const cookieArray = document.cookie.split(';');
  if (cookieArray.length === 0) {
    return RedirectToLogin(navigate);
  }
  return cookieArray
    .map((item) => item.trim())
    .find((item) => item.startsWith('access_token'));
};

export const SetCsrfToken = (navigate) => {
  const cookieArray = document.cookie.split(';');
  if (cookieArray.length === 0) {
    return RedirectToLogin(navigate);
  }
  return cookieArray
    .map((item) => item.trim())
    .find((item) => item.startsWith('csrftoken'));
};

export const setCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
};

export const getCookieValue = (name) => {
  const cookieArray = document.cookie.split(';');
  return cookieArray
    .map((item) => item.trim())
    .find((item) => item.startsWith(name))
    .split('=')[1];
};
