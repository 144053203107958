/* eslint-disable jsx-quotes */
import { Box, Button, Modal, Typography } from '@mui/material';

import { ModalClose } from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';

function ConfirmationModal({ open, onClose, onSubmit }) {
  return (
    <Modal open={open}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '30%',
          height: '20%',
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          padding: '1%',

          minWidth: '400px',
          overflow: 'scroll',
        }}
      >
        <ModalClose onClick={onClose} color='#434343' />
        <WarningIcon color='warning' fontSize='large' />
        <Typography>
          Do you confirm to submit this Work Order to Yardi?
        </Typography>
        <Button
          variant='contained'
          color='success'
          sx={{ borderRadius: 2, textTransform: 'none' }}
          onClick={() => {
            onClose();
            onSubmit();
          }}
        >
          Submit WO to Yardi
        </Button>
      </Box>
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ConfirmationModal;
