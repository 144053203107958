/* eslint-disable jsx-quotes */
import { Box, Button, Grid, Modal, Typography } from '@mui/material';

import { ModalClose } from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';

function RefreshModal({ open, onClose, onProceed }) {
  return (
    <Modal open={open}>
      <div className='submit-popup-container'>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '30%',
            height: '20%',
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            padding: '1%',
            paddingRight: '2%',
            paddingLeft: '2%',
            textAlign: 'center',

            minWidth: '400px',
            overflow: 'scroll',
          }}
        >
          <Grid container spacing={2}>
            <ModalClose onClick={onClose} color='#434343' />
            <Grid item xs={12}>
              <WarningIcon color='warning' fontSize='large' />
            </Grid>
            <Grid item xs={12}>
              <Typography>Your progress is this chat will be lost</Typography>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant='contained'
                sx={{ borderRadius: 2, textTransform: 'none', width: '100%' }}
                color='success'
                onClick={() => {
                  onClose();
                  onProceed();
                }}
              >
                Proceed
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant='contained'
                sx={{
                  borderRadius: 2,
                  textTransform: 'none',
                  width: '100%',
                }}
                onClick={onClose}
                color='error'
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    </Modal>
  );
}

RefreshModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
};

export default RefreshModal;
