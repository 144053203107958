/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable operator-linebreak */
/* eslint-disable jsx-quotes */
/* eslint-disable react/jsx-filename-extension */
import './loginForm.css';

import {
  Box,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import {
  getAddressSearchResponse,
  getLeaseholderResponse,
} from '../../services/chatBotService';
import { getCookieValue, setCookie } from '../../util/cookies';
import { isEmpty, sortBy, startCase } from 'lodash';

import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { IMaskInput } from 'react-imask';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

const TextMaskCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(000)000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      autoComplete="off"
      validate={false}
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

TextMaskCustom.displayName = 'TextMaskCustom';

function LoginForm({
  setUnitCode,
  setLoginFormSubmitted,
  closeForm,
  openForm,
}) {
  const [addressOptions, setAddressOptions] = useState([]);
  const [addressSearchText, setAddressSearchText] = useState(null);
  const [addressResponse, setAddressResponse] = useState({});
  const [selectedAddress, setSelectedAddress] = useState({});
  const [neighborhood, setNeighborhood] = useState([]);
  const [leaseholder, setLeaseholder] = useState([]);
  const [unitOccupied, setUnitOccupied] = useState(true);
  const [tenantDetails, setTenantDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const getAccessNotes = () => {
    const accessNotesObject = {
      createdBy: getCookieValue('username'),
      ...(!isEmpty(tenantDetails.alternativePhoneNumber)
        ? { ALT_PHONE: tenantDetails.alternativePhoneNumber }
        : {}),
      ...(!isEmpty(tenantDetails.alternativeEmail)
        ? { ALT_EMAIL: tenantDetails.alternativeEmail }
        : {}),
      ...(!isEmpty(tenantDetails.alternativePhoneNumberForText)
        ? { TEXT_MSG: tenantDetails.alternativePhoneNumberForText }
        : {}),
    };

    if (Object.keys(accessNotesObject).length === 0) return '';
    return Object.entries(accessNotesObject)
      ?.filter(([_, value]) => !isEmpty(value))
      .map(([key, value]) => `${startCase(key)}: ${value}`)
      .join('; ');
  };

  // Email validation function
  const isValidEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
  };

  const formik = useFormik({
    initialValues: {
      alternativeEmail: '',
      alternativePhoneNumber: '',
      alternativePhoneNumberForText: '',
      leaseHolderEmail: false,
      leaseHolderPhoneNumber: false,
      address: undefined,
      yardiUnitCode: '',
      neighborhood: '',
      project: '',
      leaseholder: undefined,
    },
    validate: (formData) => {
      const errors = {};
      if (
        (formData.leaseHolderEmail === false &&
          formData.alternativeEmail === '') ||
        (formData.leaseHolderPhoneNumber === false &&
          (formData.alternativePhoneNumber === '' ||
            formData.alternativePhoneNumberForText === '')) ||
        !formData.address ||
        !formData.yardiUnitCode ||
        !formData.leaseholder
      ) {
        errors.name = 'Required';
      }

      // Email format validation
      if (
        formData.alternativeEmail &&
        !isValidEmail(formData.alternativeEmail)
      ) {
        errors.alternativeEmail = 'Invalid email format';
      }

      // alternative phone number length validation
      if (
        formData.alternativePhoneNumber &&
        formData.alternativePhoneNumber.length !== 13
      ) {
        errors.alternativePhoneNumber = 'Phone number must be 10 digits';
      }

      // alternative phone number for text length validation
      if (
        formData.alternativePhoneNumberForText &&
        formData.alternativePhoneNumberForText.length !== 13
      ) {
        errors.alternativePhoneNumberForText =
          'Phone number for text must be 10 digits';
      }
      setIsSubmitDisabled(Object.keys(errors).length);
      return errors;
    },
    onSubmit: (formData) => {
      try {
        setLoginFormSubmitted(true);

        const tenantDetailsWithAccessNotes = {
          ...tenantDetails,
          accessNotes: getAccessNotes(),
        };

        sessionStorage.setItem(
          'tenantDetails',
          JSON.stringify(tenantDetailsWithAccessNotes)
        );
        setCookie('tenant_code', tenantDetails.tenantCode, 1);
        setCookie('neighborhood_code', tenantDetails.neighborhood, 1);
        if (unitOccupied) closeForm(false);
      } catch (error) {
        console.log('Error while submitting :: ', error);
      }
    },
  });

  const resetAddressOptions = () => {
    setAddressOptions([]);
    setNeighborhood([]);
    setLeaseholder([]);
    setSelectedAddress([]);
    setAddressSearchText(null);
    setTenantDetails({});
    formik.resetForm();
  };

  const handleAddressOptionChange = (event, query) => {
    if (query) {
      setAddressSearchText(query);
      if (query.length >= 2) {
        getAddressSearchResponse(query)
          .then((response) => {
            const keys = Object.keys(response);
            setAddressOptions(keys);
            setAddressResponse(response);
            if (Object.keys(response).length === 1) {
              const currentAddress = Object.keys(response)[0];
              setSelectedAddress(response[currentAddress]);
              formik.setFieldValue('address', currentAddress);
              const neighborhoodOptions = response[currentAddress].map(
                (_address) => ({
                  neighbourhood: _address.neighborhood,
                  neighborhoodName: _address.neighborhoodName,
                })
              );
              setNeighborhood(sortBy(neighborhoodOptions, 'neighborhoodName'));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        resetAddressOptions();
        setUnitOccupied(true);
      }
    } else {
      resetAddressOptions();
    }
  };

  const handleSelectAddress = (event, address) => {
    if (address) {
      formik.handleChange(event);
      formik.setFieldValue('address', address);
      setSelectedAddress(addressResponse[address]);
      setTenantDetails((prevState) => ({
        ...prevState,
        address,
      }));
      const neighborhoodOptions = addressResponse[address].map((_address) => ({
        neighbourhood: _address.neighborhood,
        neighborhoodName: _address.neighborhoodName,
      }));
      setNeighborhood(sortBy(neighborhoodOptions, 'neighborhoodName'));
      setAddressOptions([]);
    } else {
      setAddressOptions([]);
      formik.setFieldValue('address', '');
      setNeighborhood([]);
      formik.setFieldValue('neighborhood', '');
    }
  };

  const handleNeighborhoodSelect = (e, value) => {
    if (value) {
      const selectedNeighborhood = value;
      formik.handleChange(e);
      const currentAddress = selectedAddress.find(
        (address) =>
          address.neighborhoodName === selectedNeighborhood.neighborhoodName
      );
      formik.setValues({
        ...formik.values,
        neighborhood: selectedNeighborhood,
        yardiUnitCode: currentAddress.unitCode,
        project: currentAddress.project,
      });
      setUnitCode(currentAddress.unitCode);
      setTenantDetails((prevState) => ({
        ...prevState,
        ...currentAddress,
      }));

      setLoading(true);
      getLeaseholderResponse(currentAddress.unitCode)
        .then((leaseholderResponse) => {
          setLeaseholder(sortBy(leaseholderResponse?.data, 'first_name'));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const handleLeaseholderSelect = (e, value) => {
    if (value) {
      const selectedLeaseholder = value;
      formik.handleChange(e);
      formik.setValues({
        ...formik.values,
        leaseholder: selectedLeaseholder,
        leaseHolderEmail: false,
        leaseHolderPhoneNumber: false,
        alternativePhoneNumber: '',
        alternativePhoneNumberForText: '',
        alternativeEmail: '',
      });

      const leaseholderDetails = leaseholder.find(
        (details) =>
          `${details.first_name} ${details.last_name}` ===
          `${selectedLeaseholder.first_name} ${selectedLeaseholder.last_name}`
      );

      if (leaseholderDetails) {
        setTenantDetails((prevState) => ({
          ...prevState,
          tenantCode: leaseholderDetails?.tenant_code,
          requestorName: `${leaseholderDetails?.first_name} ${leaseholderDetails?.last_name}`,
          type: leaseholderDetails?.type,
          requestorPhoneNumber: leaseholderDetails?.phone_num,
          requestorEmail: leaseholderDetails?.email,
          unitOccupied: leaseholderDetails.is_vacant === 'Occupied',
        }));
        setUnitOccupied(leaseholderDetails.is_vacant === 'Occupied');
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setTenantDetails((prevState) => ({
      ...prevState,
      // if lease holder email is selected then update it in requestor email
      // else provider alternative email
      requestorEmail: formik.values.leaseHolderEmail
        ? formik.values.leaseholder.email
        : formik.values.alternativeEmail,

      alternativeEmail: formik.values.alternativeEmail,

      // if lease holder phone is selected then update it in requestor phone
      // else provider alternative phone
      requestorPhoneNumber: formik.values.leaseHolderPhoneNumber
        ? formik.values.leaseholder.phone_num
        : formik.values.alternativePhoneNumber,
      alternativePhoneNumber: formik.values.alternativePhoneNumber,

      alternativePhoneNumberForText:
        formik.values.alternativePhoneNumberForText,
    }));

    formik.handleSubmit();
  };

  return (
    <Modal
      disableEnforceFocus
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      open={openForm}
    >
      <div className="submit-popup-container">
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '60%',
              height: '90%',
              bgcolor: 'background.paper',
              borderRadius: 2,
              boxShadow: 24,
              display: 'flex',
              flexDirection: 'column',

              minWidth: '500px',
              overflow: 'scroll',
            }}
          >
            <Box sx={{ overflow: 'auto', flexGrow: 1, p: 4 }}>
              <Box marginBottom={3}>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  marginBottom={1}
                >
                  <Typography
                    id="modal-modal-title"
                    color="#52663c"
                    fontSize={24}
                  >
                    Resident Unit Identification
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  marginBottom={2}
                  gap={2}
                >
                  <Autocomplete
                    fullWidth
                    autoFocus
                    autoHighlight
                    options={addressOptions}
                    id="address"
                    onInputChange={handleAddressOptionChange}
                    onChange={handleSelectAddress}
                    noOptionsText={
                      addressSearchText
                        ? 'No results found. Please refine your search.'
                        : 'Type at least 3 characters to see available options.'
                    }
                    renderInput={(params) => (
                      <TextField
                        required
                        fullWidth
                        helperText="Example: 123 Apple Court"
                        {...params}
                        label="Address"
                        variant="standard"
                        color="success"
                        autoFocus
                        autoComplete="off"
                      />
                    )}
                  />
                  <TextField
                    id="neighborhood"
                    fullWidth
                    select
                    required
                    value={formik.values.neighborhood}
                    onChange={(e) =>
                      handleNeighborhoodSelect(e, e.target.value)
                    }
                    variant="standard"
                    color="success"
                    label="Neighborhood"
                    disabled={Object.keys(selectedAddress).length === 0}
                  >
                    {neighborhood.map((option) => (
                      <MenuItem key={option.neighborhoodName} value={option}>
                        {option.neighborhoodName}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    id="unit-code"
                    label="Yardi unit code"
                    variant="standard"
                    required
                    disabled
                    fullWidth
                    value={formik.values.yardiUnitCode}
                  />
                  <TextField
                    id="project"
                    label="Project"
                    variant="standard"
                    required
                    disabled
                    fullWidth
                    value={formik.values.project}
                  />
                  <TextField
                    id="leaseholder"
                    fullWidth
                    select
                    color="success"
                    required
                    value={formik.values.leaseholder}
                    onChange={(e) => handleLeaseholderSelect(e, e.target.value)}
                    variant="standard"
                    label={`${
                      loading ? 'Loading lease holders' : 'Lease holder name'
                    }`}
                    disabled={
                      (formik.values.yardiUnitCode === '' &&
                        leaseholder.length === 0) ||
                      loading
                    }
                  >
                    {leaseholder?.map((option) => (
                      <MenuItem key={option.first_name} value={option}>
                        {`${option.first_name} ${option.last_name} ( ${option.type} )`}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography
                    id="modal-modal-title"
                    color="#52663c"
                    fontSize={24}
                  >
                    Followup Communication Preferences
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  gap={1}
                >
                  <FormGroup sx={{ width: '100%' }}>
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="success"
                              name="leaseHolderEmail"
                              checked={formik.values.leaseHolderEmail}
                              onChange={formik.handleChange}
                              disabled={
                                isEmpty(formik.values.leaseholder) ||
                                loading ||
                                isEmpty(tenantDetails) ||
                                (tenantDetails.tenantCode &&
                                  isEmpty(tenantDetails.requestorEmail))
                              }
                            />
                          }
                          label="E-Mail Leaseholder"
                        />

                        {formik.values.yardiUnitCode !== '' &&
                          leaseholder.length !== 0 && (
                            <FormHelperText
                              error={
                                tenantDetails.tenantCode &&
                                isEmpty(tenantDetails.requestorEmail)
                              }
                            >
                              {tenantDetails.tenantCode &&
                              isEmpty(tenantDetails.requestorEmail)
                                ? 'No E-Mail on file'
                                : tenantDetails.requestorEmail}
                            </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="leaseHolderPhoneNumber"
                              color="success"
                              checked={formik.values.leaseHolderPhoneNumber}
                              onChange={formik.handleChange}
                              disabled={
                                isEmpty(formik.values.leaseholder) ||
                                loading ||
                                isEmpty(tenantDetails) ||
                                (tenantDetails.tenantCode &&
                                  isEmpty(tenantDetails.requestorPhoneNumber))
                              }
                            />
                          }
                          label="Leaseholder Callback"
                        />
                        {formik.values.yardiUnitCode !== '' &&
                          leaseholder.length !== 0 && (
                            <FormHelperText
                              error={
                                tenantDetails.tenantCode &&
                                isEmpty(tenantDetails.requestorPhoneNumber)
                              }
                            >
                              {tenantDetails.tenantCode &&
                              isEmpty(tenantDetails.requestorPhoneNumber)
                                ? 'No phone on file'
                                : tenantDetails.requestorPhoneNumber}
                            </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  </FormGroup>
                  <TextField
                    id="alternative-email"
                    color="success"
                    label={
                      tenantDetails.tenantCode &&
                      isEmpty(tenantDetails.requestorEmail)
                        ? 'No email on file, please provide an alternate'
                        : 'Please provide an alternate email'
                    }
                    variant="standard"
                    fullWidth
                    name="alternativeEmail"
                    value={formik.values.alternativeEmail}
                    onChange={formik.handleChange}
                    required={!formik.values.leaseHolderEmail}
                    disabled={isEmpty(formik.values.leaseholder)}
                    helperText={
                      formik.touched.alternativeEmail &&
                      formik.errors.alternativeEmail
                    }
                    error={
                      formik.touched.alternativeEmail &&
                      Boolean(formik.errors.alternativeEmail)
                    }
                    onBlur={formik.handleBlur}
                  />
                  <TextField
                    id="alternative-phone-number"
                    color="success"
                    label={
                      tenantDetails.tenantCode &&
                      isEmpty(tenantDetails.requestorPhoneNumber)
                        ? 'No phone on file, please provide an alternate'
                        : 'Please provide an alternate phone'
                    }
                    variant="standard"
                    fullWidth
                    name="alternativePhoneNumber"
                    onChange={formik.handleChange}
                    value={formik.values.alternativePhoneNumber}
                    required={!formik.values.leaseHolderPhoneNumber}
                    InputProps={{
                      inputComponent: TextMaskCustom,
                    }}
                    disabled={isEmpty(formik.values.leaseholder)}
                    error={
                      formik.touched.alternativePhoneNumber &&
                      Boolean(formik.errors.alternativePhoneNumber)
                    }
                    helperText={
                      formik.touched.alternativePhoneNumber &&
                      formik.errors.alternativePhoneNumber
                        ? formik.errors.alternativePhoneNumber
                        : 'Example: (555)867-5309'
                    }
                    onBlur={formik.handleBlur}
                  />
                  <TextField
                    id="alternative-phone-number-for-text-messages"
                    color="success"
                    label={
                      tenantDetails.tenantCode &&
                      isEmpty(tenantDetails.requestorPhoneNumber)
                        ? 'No phone on file, please provide an alternate for SMS Updates'
                        : 'Please provide an alternate phone for SMS Updates'
                    }
                    variant="standard"
                    fullWidth
                    name="alternativePhoneNumberForText"
                    value={formik.values.alternativePhoneNumberForText}
                    onChange={formik.handleChange}
                    required={!formik.values.leaseHolderPhoneNumber}
                    InputProps={{
                      inputComponent: TextMaskCustom,
                    }}
                    disabled={isEmpty(formik.values.leaseholder)}
                    error={
                      formik.touched.alternativePhoneNumberForText &&
                      Boolean(formik.errors.alternativePhoneNumberForText)
                    }
                    helperText={
                      formik.touched.alternativePhoneNumberForText &&
                      formik.errors.alternativePhoneNumberForText
                        ? formik.errors.alternativePhoneNumberForText
                        : 'Example: (555)867-5309'
                    }
                    onBlur={formik.handleBlur}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              p={2}
              sx={{ borderTop: '1px solid #e0e0e0' }}
            >
              <Button
                variant="contained"
                type="submit"
                color={unitOccupied ? 'success' : 'error'}
                disabled={!unitOccupied || isSubmitDisabled}
                sx={{ borderRadius: 2, textTransform: 'none' }}
              >
                Submit
              </Button>
              {!unitOccupied && (
                <FormHelperText error>Unit is not occupied</FormHelperText>
              )}
            </Box>
          </Box>
        </form>
      </div>
    </Modal>
  );
}

LoginForm.propTypes = {
  setUnitCode: PropTypes.func.isRequired,
  setLoginFormSubmitted: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
  openForm: PropTypes.bool.isRequired,
};

export default LoginForm;
