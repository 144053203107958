/* eslint-disable import/order */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable arrow-spacing */
/* eslint-disable space-in-parens */
/* eslint-disable operator-linebreak */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable indent */
/* eslint-disable jsx-quotes */
/* eslint-disable react/jsx-indent */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';

import AvatarS from './avatar';
import PropTypes from 'prop-types';
import { chatInfoForm } from '../services/chatBotService';

function ChatText({
  text,
  user = 0,
  chatFormDisplay,
  setDialogFlow,
  index,
  messageCount,
  avatar,
  displayViewSummaryButton,
  openSummaryForm,
  leaseholder,
  undoChatResponse = (value) => {},
}) {
  const [parsedJson, setParsedJson] = useState({});
  const isJson = typeof text === 'object';
  const handleParsedData = (parsedData) => {
    setParsedJson(parsedData);
    const technicianNotes = parsedData.technician_notes;
    if (parsedData.wo_created === 'True') {
      setDialogFlow({ ...parsedData, technician_notes: technicianNotes });
    } else {
      chatInfoForm({ ...parsedData, technician_notes: technicianNotes });
    }
  };

  useEffect(() => {
    try {
      if (isJson) {
        handleParsedData(text);
      } else {
        setParsedJson({});
      }
    } catch (error) {
      setParsedJson({});
    }
  }, []);

  const displayTechnicianNote = (technicianNote) => {
    if (technicianNote && technicianNote.length > 0) {
      return technicianNote.map((item) => <div key={item}>{item}</div>);
    }
    return null;
  };

  return (
    <div>
      <div
        className={`chat-text ${user ? 'left' : 'right'}`}
        style={{ marginBottom: '10px' }}
      >
        {avatar}
        <label
          className={
            text === 'Please type a response below...' && 'waiting-message'
          }
          style={{ marginLeft: '5px', marginRight: '5px' }}
        >
          {!isJson
            ? text
            : Object.keys(parsedJson).map((key) => (
                <pre key={key} className="wrapped-pre">
                  <strong>{key}:</strong>
                  {key === 'technician_notes'
                    ? displayTechnicianNote(parsedJson[key])
                    : parsedJson[key]}
                </pre>
              ))}
        </label>
        {!user && <AvatarS user={user} />}
      </div>
      {displayViewSummaryButton && (
        <div className="chat-text left" style={{ marginBottom: '10px' }}>
          <AvatarS user={1} />
          <label style={{ marginLeft: '5px', marginRight: '5px' }}>
            <span className="view-summary-button" onClick={openSummaryForm}>
              View Summary & Submit WO{' '}
            </span>
          </label>
        </div>
      )}
      {/* {!user && (messageCount - 2 === index || messageCount - 3 === index) && (
        <div
          className={`chat-text ${!user ? 'right' : ''}`}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            undoChatResponse('/undo');
          }}
        >
          <u>Undo</u>
        </div>
      )} */}
    </div>
  );
}
ChatText.propTypes = {
  text: PropTypes.string.isRequired, // Define PropTypes for 'text'
  user: PropTypes.number.isRequired, // Define PropTypes for 'user'
  chatFormDisplay: PropTypes.func.isRequired, // Define PropTypes for 'chatFormDisplay'
  setDialogFlow: PropTypes.func.isRequired, // Define PropTypes for 'setDialogFlow'
  index: PropTypes.number.isRequired,
  avatar: PropTypes.node.isRequired,
  messageCount: PropTypes.number.isRequired,
  undoChatResponse: PropTypes.func.isRequired,
  displayViewSummaryButton: PropTypes.bool.isRequired,
  openSummaryForm: PropTypes.func.isRequired,
  leaseholder: PropTypes.string.isRequired,
};
export default ChatText;
